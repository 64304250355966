/* Provide sufficient contrast against white background */
@font-face {
    font-family: 'Bebas neue';
    font-weight: bold;
    font-style: normal;
    src: url("./styles/BebasNeue-bold.ttf") format("truetype");
}

@font-face {
    font-family: 'Bebas neue';
    font-weight: normal;
    font-style: normal;
    src: url("./styles/BebasNeue.ttf") format("truetype");
}

@font-face {
    font-family: 'Open Sans';
    font-weight: bold;
    font-style: normal;
    src: url("./styles/OpenSans-Bold.ttf") format("truetype");
}

@font-face {
    font-family: 'Open Sans';
    font-weight: normal;
    font-style: normal;
    src: url("./styles/OpenSans.ttf") format("truetype");
}